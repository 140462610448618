<template>
	<standard-container menu-id="promozioni">
		<v-alert outlined text type="info"><div class="title">Lavori in corso...</div></v-alert>
	</standard-container>
</template>

<script>
/**********************************************************************************************
**** Promozioni ***********************************************************************************
***********************************************************************************************
* 
*/
import StandardContainer from '@/components/standard-container'
export default {
	name: 'PromozioniPage',
	components: { StandardContainer },
	data: () => {
		return {
		}
	},
	computed: {
		viewDebugElements() { return this.$store.getters['viewDebugElements'] },
	},
	mounted() {
	},
	methods: {

	},
}
</script>

<style scoped lang="less">

</style>